// /src/components/mdm/components/dataManagement/dmGrid.js
import React, { Component } from "react";
import "components/powerBI/components/report/style/style.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setFullLoad, setRefresh } from "components/mdm/reducers/mdmReducer";

import { GlobalAgGrid } from "lib/globalAgGrid";
import { cloneDeep } from "lodash";
import "../../style.css";
// import axios from "axios";

import { EditableCellRenderer } from "components/agGrid/renderers";
import FKCellEditor from "./fkCellEditor";
// cell renderer class
// function isObject(value) {
//   return value && typeof value === "object" && value.constructor === Object;
// }
// function FKKeyCreator(params) {
//   if (isObject(params.value) && params.value.name !== undefined) {
//     return params.value.name;
//   } else if (params.value !== undefined) {
//     return params.value;
//   } else {
//     return "";
//   }
// }
function isObject(value) {
  return value && typeof value === "object" && value.constructor === Object;
}
function FKComparator(item1, item2, node1, node2) {
  if (item1 === null) {
    return -1;
  } else if (item2 === null) {
    return 1;
  }
  if (item1.name === null) {
    return -1;
  } else if (item2.name === null) {
    return 1;
  }

  if (item1.name > item2.name) {
    return 1;
  } else if (item1.name < item2.name) {
    return -1;
  } else {
    return 0;
  }
}
function FKCellRenderer() {}
FKCellRenderer.prototype.init = function(params) {
  this.eGui = document.createElement("div");
  var text = "";
  if (params.colDef.editable) {
    this.eGui.className = "editable";
  }
  if (params.data !== undefined) {
    if (params.data[params.colDef.field] !== undefined && params.data[params.colDef.field] !== null) {
      if (params.data[params.colDef.field].name !== null && params.data[params.colDef.field].name !== undefined) {
        text = params.data[params.colDef.field].name;
        if (params.data[params.colDef.field].status === "invalid") {
          this.eGui.className = this.eGui.className + " paste-error ";
        }
      } else {
        text = params.data[params.colDef.field];
      }
    }
  }
  if (typeof text === "object") {
    text = "";
  }
  this.eGui.innerHTML = text.toString();
};
FKCellRenderer.prototype.getGui = function() {
  return this.eGui;
};
function FKCellEditorRenderer() {}
FKCellEditorRenderer.prototype.init = function(params) {
  this.eGui = document.createElement("span");
  var text = "";
  if (params.value !== null && params.value !== undefined) {
    if (params.value.name !== null && params.value.name !== undefined) {
      text = params.value.name;
    }
  }

  this.eGui.innerHTML = text.toString();
};
FKCellEditorRenderer.prototype.getGui = function() {
  return this.eGui;
};

// ComboBoxEditor.prototype.getValue = function() {
//   if (this.eGui.name.includes("FK")) {
//     if (this.eGui.options[this.eGui.selectedIndex].value === "null") {
//       return {
//         id: null,
//         name: this.eGui.options[this.eGui.selectedIndex].text
//       };
//     } else {
//       return {
//         id: this.eGui.options[this.eGui.selectedIndex].value,
//         name: this.eGui.options[this.eGui.selectedIndex].text
//       };
//     }
//   } else {
//     if (this.eGui.options[this.eGui.selectedIndex].value === "null") {
//       return "";
//     } else {
//       return this.eGui.options[this.eGui.selectedIndex].value;
//       // return {
//       //   id: this.eGui.options[this.eGui.selectedIndex].value,
//       //   name: this.eGui.options[this.eGui.selectedIndex].text
//       // };
//     }
//   }
// };

function ComboBoxEditor() {}

ComboBoxEditor.prototype.init = function(params) {
  this.params = params;
  this.selectedValue = "";
  if (params.value === undefined || params.value === null) {
    this.originalValue = null;
  } else {
    if (isObject(params.value)) {
      this.originalValue = params.value.name;
    } else {
      this.originalValue = params.value;
    }
  }
  this.originalValue = params.value;

  // Create the editor container
  this.eGui = document.createElement("div");
  this.eGui.setAttribute("class", "combo-box-editor");

  // Create an input field
  this.input = document.createElement("input");
  this.input.type = "text";
  this.input.setAttribute("class", "form-control");
  this.input.setAttribute("placeholder", "Type to search...");
  this.eGui.appendChild(this.input);
  this.eGui.setAttribute("name", params.colDef.field);

  // Create a dropdown container
  this.dropdown = document.createElement("ul");
  this.dropdown.setAttribute("name", params.colDef.field);
  this.dropdown.setAttribute("class", "dropdown-list");
  this.dropdown.style.position = "absolute";
  this.dropdown.style.backgroundColor = "#fff";
  this.dropdown.style.border = "1px solid #ccc";
  this.dropdown.style.zIndex = "1000";
  this.dropdown.style.padding = "0";
  this.dropdown.style.margin = "0";
  this.dropdown.style.maxHeight = "200px";
  this.dropdown.style.overflowY = "auto";
  this.dropdown.style.display = "none"; // Initially hidden
  this.dropdown.style.paddingTop = "4px";
  this.eGui.appendChild(this.dropdown);

  if (this.originalValue && this.originalValue.name) {
    this.input.value = this.originalValue.name;
  } else if (typeof this.originalValue === "string") {
    this.input.value = this.originalValue;
  }

  this.handleFocus = () => {
    // Show dropdown on focus if there are fewer than 15000 items
    if (params.values && params.values.length < 15000) {
      this.updateDropdown("", params.values);
    }
  };

  this.handleInput = event => {
    const query = event.target.value.trim();
    if (query.length >= 3) {
      this.updateDropdown(query, params.values); // Fetch and filter the results
    } else {
      if (params.values && params.values.length >= 15000) {
        this.dropdown.style.display = "none"; // Hide dropdown if input is less than 3 characters
      }
    }
  };
  this.handleKeyDown = e => {
    if (e.key === "Delete" && this.dropdown.style.display === "block") {
      this.selectedValue = { id: null, name: "" };
      this.input.value = "";
      this.input.text = null;
      this.dropdown.style.display = "none";
      try {
        if (this.params?.api?.stopEditing) {
          this.params.api.stopEditing();
        }
      } catch (err) {
        //do nothing
      }
    }
  };

  // Close dropdown when clicking outside
  this.handleOutsideClick = event => {
    if (!this.eGui.contains(event.target)) {
      this.dropdown.style.display = "none";
      this.handleBlur();
    }
  };

  document.addEventListener("keydown", this.handleKeyDown);
  document.addEventListener("click", this.handleOutsideClick);
  this.input.addEventListener("focus", this.handleFocus);
  this.input.addEventListener("input", this.handleInput);
};

ComboBoxEditor.prototype.getGui = function() {
  return this.eGui;
};

ComboBoxEditor.prototype.getValue = function() {
  if (!this.selectedValue) {
    return this.originalValue;
  }

  if (isObject(this.selectedValue)) {
    if (this.selectedValue.id === null || this.selectedValue.id === undefined) {
      return this.selectedValue.name;
    } else if (this.params.colDef.field.includes("FK")) {
      return { id: this.selectedValue.id, name: this.selectedValue.name };
    } else {
      return this.selectedValue.id;
    }
  }
  return this.selectedValue;
};

ComboBoxEditor.prototype.afterGuiAttached = function() {
  this.input.focus();
};

ComboBoxEditor.prototype.updateDropdown = async function(query, values) {
  // Fetch or filter the dropdown items
  const filteredValues = await this.fetchPicklistValues(query, values);

  // Clear existing dropdown items
  this.dropdown.innerHTML = "";

  // Add new items to the dropdown
  filteredValues.forEach(item => {
    const listItem = document.createElement("li");

    listItem.textContent = item.name;
    listItem.setAttribute("data-value", item.id);
    if (item.id === null) {
      listItem.style.height = "24px";
    }
    listItem.style.padding = "8px";
    listItem.style.cursor = "pointer";
    listItem.style.listStyle = "none";

    listItem.classList.add("dropdown-item");

    // Highlight the selected item
    listItem.addEventListener("click", () => {
      this.selectedValue = { id: item.id, name: item.name };
      this.input.value = item.name;
      this.input.text = item.id;
      this.dropdown.style.display = "none"; // Hide dropdown after selection
      // Notify the grid that editing is complete
      try {
        if (this.params !== undefined && this.params.api !== undefined && this.params.api.stopEditing !== undefined) {
          this.params.api.stopEditing();
        }
      } catch (err) {
        //do nothing
      }
    });

    this.dropdown.appendChild(listItem);
  });

  // Show the dropdown
  this.dropdown.style.display = filteredValues.length > 0 ? "block" : "none";
};

// Simulate API or local filtering logic
ComboBoxEditor.prototype.fetchPicklistValues = async function(query, values) {
  // Replace this with your server API if needed
  const allValues = values;

  // Filter values based on the query
  return allValues.filter(item => {
    if (item.name === null) {
      return 1;
    }
    return item.name.toLowerCase().includes(query.toLowerCase());
  });
};
ComboBoxEditor.prototype.handleBlur = function() {
  // This method will be called when the input loses focus
  // Stop editing to finalize the value

  try {
    if (this.params !== undefined && this.params.api !== undefined && this.params.api.stopEditing !== undefined) {
      this.params.api.stopEditing();
    }
  } catch (err) {
    //do nothing
  }
};

ComboBoxEditor.prototype.destroy = function() {
  document.removeEventListener("keydown", this.handleKeyDown);
  document.removeEventListener("click", this.handleOutsideClick);
  this.input.removeEventListener("focus", this.handleFocus);
  this.input.removeEventListener("input", this.handleInput);
};

function checkboxCellRenderer(params) {
  if (params.colDef.editable) return `<input type='checkbox' ${params.value ? "checked" : ""} />`;
  else return `<input type='checkbox' disabled=true ${params.value ? "checked" : ""} />`;
}
function NonNullCellRenderer() {}
NonNullCellRenderer.prototype.init = function(params) {
  this.eGui = document.createElement("div");
  var text = "";
  this.eGui.className = "editable";
  if (params.value !== null && params.value !== "" && params.value !== undefined) {
    if (params.value.name !== null) {
      text = params.value;
    }
  } else {
    this.eGui.className = "null editable";
  }

  this.eGui.innerHTML = text.toString();
};
NonNullCellRenderer.prototype.getGui = function() {
  return this.eGui;
};

class DMGrid extends Component {
  constructor(props) {
    super(props);

    this.nrowsPerQuery = 5000;
    this.state = {
      update: false
    };

    // this.gridReady = this.gridReady.bind(this);
    this.agGrid = React.createRef();
    this.removeRows = this.removeRows.bind(this);
    // this.appendData = this.appendData.bind(this);
    // this.finishLoading = this.finishLoading.bind(this);
    this.isExternalFilterPresent = this.isExternalFilterPresent.bind(this);
    this.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);

    if (this.props.currentTable !== undefined && this.props.currentTable !== null && this.props.currentTable.length > 0) {
      this.props.currentTable.map(header => {
        if (header.cellRenderer === "FKCellRenderer" || header.cellRenderer === "EditableCellRenderer") {
          // header.keyCreator = FKKeyCreator;
          header.filterValueGetter = function(params) {
            // console.log(params);

            const fValue = params.data[params.colDef.field.split("_FK")[0]];
            return fValue;
          };
          header.comparator = FKComparator;
        }
        return header;
      });
    }
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (this.props.externalFilterUpdate) {
      if (this.agGrid.current.gridApi !== undefined) {
        this.agGrid.current.gridApi.onFilterChanged();
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }
  finishLoading() {
    if (this.props.mdmConfig[this.props.activeIdx].allowNewRows) {
      let newRows = [];
      const { data, lastId } = this.props;
      for (let i = 0; i < 1000; i++) {
        let newData = null;
        if (data.length > 0) {
          newData = cloneDeep(data[0]);

          newData = Object.entries(newData);
          newData = newData.reduce((obj, key) => {
            if (key[0] === "ID") {
              obj[key[0]] = lastId + 1 + i;
            } else {
              obj[key[0]] = null;
            }
            return obj;
          }, {});
        } else {
          newData = this.props.currentTable.reduce((obj, item) => {
            if (item.headerName === "ID") {
              obj[item.headerName] = 1 + i;
            } else {
              obj[item.headerName] = null;
            }
            return obj;
          }, {});

          // newData = newData.reduce((obj, item) => {
          //   obj[item.id] = item;
          //   return obj;
          // }, {});
        }

        newData.crudType = "empty";
        newRows.push(newData);
      }
      this.props.addNewRow(newRows);
    }
    this.props.setFullLoad(true);

    clearInterval(this.timer);
    console.log("Finished Loading");
    if (this.props.currentTable !== undefined) {
      for (let x = 1; x < this.props.currentTable.length; x++) {
        if (this.agGrid.current !== null) {
          let filter = this.agGrid.current.gridApi.getFilterInstance(x);
          if (filter !== undefined) {
            filter.resetFilterValues();
          }
        }
      }
    }

    this.timer = null;
  }

  cellValueChanged(item) {
    // console.log("cell value changed");
    // console.log(new Date());
    //var start = new Date();

    if (item.data.crudType === undefined) {
      //console.log("in setting Crud Time");
      //var start1 = new Date();
      item.data.crudType = "update";
      //let endTime1 = new Date() - start1;
      //console.info("Execution time Crud: %dms", endTime1);
    }
    if (item.data.crudType === "empty") {
      item.data.crudType = "insert";
    }
    if (item.data.updated === undefined) {
      //console.log("in setting updated");
      //var start2 = new Date();
      if (isObject(item.newValue)) {
        item.data.updated = [
          {
            name: item.colDef.field,
            oldValue: item.oldValue || "",
            newValue: item.newValue
          }
        ];
      } else {
        item.data.updated = [
          {
            name: item.colDef.field,
            oldValue: item.oldValue,
            newValue: item.newValue
          }
        ];
      }
      //let endTime2 = new Date() - start2;
      //console.info("Execution time Setting updated: %dms", endTime2);
    } else {
      //console.log("in pushing updated");
      let pushedItems = item.data.updated.map(item => {
        return item.name;
      });

      if (pushedItems.includes(item.colDef.field)) {
        item.data.updated = item.data.updated.map(updateItem => {
          if (updateItem.name === item.colDef.field) {
            if (isObject(item.newValue)) {
              updateItem.newValue = item.newValue.name;
            } else {
              updateItem.newValue = item.newValue;
            }
          }
          return updateItem;
        });
      } else {
        item.data.updated.push({
          name: item.colDef.field,
          oldValue: item.oldvalue,
          newValue: item.newValue
        });
      }
    }
    //var start3 = new Date();
    this.props.setDirty(true);

    //let endTime3 = new Date() - start3;
    //console.info("Execution time set State: %dms", endTime3);
    //console.log("END");
    //console.log(new Date());
    //let endTime = new Date() - start;
    //console.info("Execution time for Cell Value Changed: %dms", endTime);
    //  item.newOrModified = "Modified";
  }
  removeRows(selectedNodes) {
    this.props.removeRows(selectedNodes);
  }
  isExternalFilterPresent() {
    // if ageType is not everyone, then we are filtering
    //return ageType != "everyone";

    return this.props.externalFilter.length > 0;
  }

  doesExternalFilterPass(node) {
    const { externalFilter } = this.props;

    let retStatus = true;
    externalFilter.forEach(item => {
      if (node.data[item.field] === false) {
        retStatus = false;
      }
    });
    return retStatus;
    //return node.data[externalFilter.field] === true;
  }
  render() {
    const { rowData, error } = this.props;

    if (error !== null) {
      return <div>{error}</div>;
    }
    // console.log("THIS IS THE ROW DATA");
    // console.log(rowData);
    return (
      <div style={{ height: "100%", paddingBottom: "10px" }}>
        <GlobalAgGrid
          key={this.props.mdmConfig[this.state.activeIdx] ? this.props.mdmConfig[this.state.activeIdx].id : "default"}
          ref={this.agGrid}
          defaultColDef={{
            sortable: true
          }}
          onRowDataUpdated={params => {
            this.props.onRowDataReady(params.api);
          }}
          onDuplicateRow={(newData, index) => {
            this.props.addNewRow({
              row: newData,
              index: index
            });
          }}
          suppressScrollOnNewData={true}
          columnDefs={this.props.currentTable}
          rowData={rowData}
          enterNavigatesVerticallyAfterEdit={true}
          enterNavigatesVertically={true}
          onCellValueChanged={item => {
            this.cellValueChanged(item);
          }}
          //maxBlocksInCache={2}

          //rowModelType={"infinite"}
          //paginationAutoPageSize={true}
          //onCellEditingStopped={this.cellEditStopped}
          //onCellEditingStarted={this.cellEditStart}
          stopEditingWhenGridLosesFocus={true}
          // enable delta updates
          deltaRowDataMode={true}
          immutableData={true}
          // return id required for delta updates
          getRowNodeId={data => {
            return data.ID;
          }}
          components={{
            FKCellRenderer: FKCellRenderer,
            FKCellEditorRenderer: FKCellEditorRenderer,
            checkboxCellRenderer: checkboxCellRenderer,
            NonNullCellRenderer: NonNullCellRenderer,
            ComboBoxEditor: ComboBoxEditor,
            FKCellEditor: FKCellEditor,
            EditableCellRenderer: EditableCellRenderer
          }}
          //onBodyScroll={debounce(this.onScroll.bind(this), 100)}
          //getRowData={this.getRowData}
          removeRows={selectedNodes => {
            this.removeRows(selectedNodes);
          }}
          gridReady={() => {
            this.props.gridReady(this.agGrid);
          }}
          isExternalFilterPresent={this.isExternalFilterPresent}
          doesExternalFilterPass={this.doesExternalFilterPass}

          //getRowClass={getRowClass}
          //frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mdmConfig: state.mdm.mdmConfig,
  loadedData: state.mdm.loadedData,
  loadingData: state.mdm.loadingData,
  loadedHydratedConfig: state.mdm.loadedHydratedConfig,
  loadingAppendData: state.mdm.loadingAppendData,
  nextRowToLoad: state.mdm.nextRowToLoad,
  rowCount: state.mdm.rowCount,
  error: state.mdm.error,
  data: state.mdm.data,
  refresh: state.mdm.refresh,
  lastId: state.mdm.lastId,
  externalFilter: state.mdm.externalFilter,
  externalFilterUpdate: state.mdm.externalFilterUpdate,
  fullLoad: state.mdm.fullLoad
});
const mapDispatchToProps = dispatch => ({
  setFullLoad(status) {
    dispatch(setFullLoad(status));
  },
  setRefresh(status) {
    dispatch(setRefresh(status));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DMGrid));
